var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.confirmed === true
      ? _c("article", { staticClass: "account" }, [
          _c("h2", { staticClass: "account__heading" }, [
            _vm._v("Account Confirmed"),
          ]),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "account__content post-content confirmMessage" },
            [
              _c("p", [
                _vm._v(
                  "\n        You have successfully confirmed your account with the email "
                ),
                _c("b", [_vm._v(_vm._s(_vm.userEmail))]),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.confirmed === false
      ? _c("article", { staticClass: "account" }, [
          _c("h2", { staticClass: "account__heading" }, [
            _vm._v("Confirmation error"),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "loader", class: { "is-visible": _vm.loading } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "account__content post-content confirmMessage" },
      [_c("p", [_vm._v("Something went wrong")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }