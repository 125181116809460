<template>
  <div>
    <article class="account" v-if="confirmed === true">
      <h2 class="account__heading">Account Confirmed</h2>
      <section class="account__content post-content confirmMessage">
        <p>
          You have successfully confirmed your account with the email <b>{{ userEmail }}</b>
        </p>
      </section>
    </article>
    <article class="account" v-if="confirmed === false">
      <h2 class="account__heading">Confirmation error</h2>
      <section class="account__content post-content confirmMessage">
        <p>Something went wrong</p>
      </section>
    </article>
    <div class="loader" :class="{ 'is-visible': loading }"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      confirmed: null,
      userEmail: null,
    };
  },
  async mounted() {
    let { userId, code } = this.$route.query;
    this.loading = true;

    try {
      const { confirmed, email } = await this.$store.user.confirmEmail(userId, code);
      this.loading = false;
      this.confirmed = confirmed;

      if (confirmed) this.userEmail = email;
    } catch (error) {
      this.loading = false;
      this.confirmed = false;
      console.log(error);
    }
  },
};
</script>

<style lang="scss">
@use 'sass:math';

.confirmMessage {
  text-align: center;
}
</style>
